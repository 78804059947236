jQuery(document).ready(function($) {
  // Use a form to submit a link with a data-method attribute.
  $('[data-method]').append(function() {
    var email_clause = "";
    if ($(this).attr('data-email')) {
      email_clause = "  <input type='hidden' name='email' value='"+$(this).attr('data-email')+"'>\n";
    }
    return "\n"+
      "<form action='"+$(this).attr('href')+"' method='POST' style='display:none'>\n"+
      "  <input type='hidden' name='_method' value='"+$(this).attr('data-method')+"'>\n"+
      "  <input type='hidden' name='_token' value='"+$("meta[name='csrf-token']").attr('content')+"'>\n"+
      email_clause + "</form>\n";
  })
    .removeAttr('href')
    .attr('style', 'cursor:pointer;')
    .bind('handleClick', function() {
      var conf_msg = $(this).attr('data-confirm');
      var confirmed = true;
      if (conf_msg != undefined) {
        confirmed = confirm(conf_msg);
      }
      if (confirmed) {
        $(this).find("form").submit();
      }
    })
    .attr('onclick', '$(this).trigger(\'handleClick\')');

  // Manage API token resets
  $('a.api-token-reset-link').bind('handleClick', function() {
    var user_id = $(this).attr('data-userid');
    var user_name = $(this).attr('data-username');
    if (! confirm('Are you sure you wish to reset the API token for user "' + user_name +
                  '"? The currently existing token for this user will be disabled.')) {
      return;
    }
    var token = jQuery("meta[name='csrf-token']").attr('content');
    jQuery.ajax({
      type: 'POST',
      url: '/users/token',
      data: {'id': user_id, '_token': token },
      dataType: 'json'
    })
      .done(function(response) {
        jQuery('#new-api-token').html(response.token);
      })
      .fail(function(response) {
        jQuery('#new-api-token').html('ERROR: could not retrieve token from server');
        console.log(response);
      });
    jQuery('#api-token-modal').modal('show');
  })
    .attr('style', 'cursor:pointer;')
    .attr('onclick', '$(this).trigger(\'handleClick\')');
  $('#api-token-modal').on('hide.bs.modal', function (e) {
    // Remove the token from the DOM as soon as the modal starts to close;
    // otherwise it might sit there for a long time for clever hackers to find.
    $(this).find('#new-api-token').html('');
  });

  // Hide and show form fields in the survey editor based on the selected
  // type of page element being created.
  $('select#survey-element-create-type').change(function() {
    var type_to_enable = $(this).find('option:selected').attr('value')
    $('.survey-element-create-widget').each(function() {
      if (type_to_enable == $(this).attr('data-survey-element-type')) {
        $(this).removeClass('hidden');
      } else {
        $(this).addClass('hidden');
      }
    });
  });
});


/**
 * Video Library panels:
 *  Views and controllers for video Panels, Menus, and MenuItems.
 */

BlVideo = {
  makePlaylist: function(id, name) {
    return {
      id: Number(id),
      name: name
    };
  },

  videoListDefaults: {
    name: '',
    // keys are playlist IDs, values are playlist names
    allPlaylists: [],
    csrfToken: '',
    domNode: null,
    widgetsById: {},
    controls: null,
    widgetIsInDom: function(widget) {
      return jQuery.contains(this.domNode, widget.domNode);
    },
    addWidget: function(widget, append) {
      // add the widget to our index of widgets
      this.widgetsById[widget.id] = widget;
      // inform the widget that it has a new parent list
      widget.list = this
      // if necessary, append the widget to our DOM
      if (append && (! this.widgetIsInDom(widget))) {
        jQuery(this.domNode).append(widget.domNode);
      }
      // rerender the widget
      widget.renderToDom();
      return this;
    },
    removeWidget: function(widget) {
      // remove the widget from our index of widgets
      delete this.widgetsById[widget.id];
      // remove the widget reference to its parent list
      widget.list = null;
      // if necessary, remove the widget from our DOM
      if (this.widgetIsInDom(widget)) {
        jQuery(widget.domNode).detach();
      }
      return this;
    },
    shuffleWidgetUp: function(widget) {
      var prev = jQuery(widget.domNode).prev();
      if (prev.length > 0) {
        jQuery(widget.domNode).detach();
        prev.before(widget.domNode);
        this.alertChanged();
      }
      return this;
    },
    shuffleWidgetDown: function(widget) {
      var next = jQuery(widget.domNode).next();
      if (next.length > 0) {
        jQuery(widget.domNode).detach();
        next.after(widget.domNode);
        this.alertChanged();
      }
      return this;
    },
    // Should be called whenever the list content changes.
    alertChanged: function() {
      // by default this does nothing
      return this;
    }
  },

  makeVideoList: function(name, list_node, controls) {
    var list = Object.create(this.videoListDefaults);
    list.name = name;
    list.domNode = list_node;
    list.controls = controls;
    // pull the playlists and csrfToken out of the top-level element
    var list_jq = jQuery(list_node);
    list.allPlaylists = [];
    var playlist_map = list_jq.data('all-playlists') || {};
    for (var id in playlist_map) {
      if (playlist_map.hasOwnProperty(id)) {
        list.allPlaylists.push(this.makePlaylist(id, playlist_map[id]));
      }
    }
    list.csrfToken = jQuery("meta[name='csrf-token']").attr('content');
    list.widgetsById = {};
    return list;
  },

  videoWidgetDefaults: {
    id: null,
    vid_id: null,
    name: null,
    playlist_ids: [],
    curationStatus: null,
    youTubeStatus: null,

    domNode: null,
    list: null,
    controls: null,

    csrfToken: function() {
      return this.list.csrfToken;
    },
    isPending: function() {
      return (this.curationStatus == 'CURATION_PENDING');
    },
    isArchived: function() {
      return (this.curationStatus == 'CURATOR_ARCHIVED');
    },
    isTrashed: function() {
      return (this.curationStatus == 'CURATOR_DELETED');
    },
    isYouTubeReady: function() {
      return (this.youTubeStatus == 'YOUTUBE_UNKNOWN');
    },
    isInPlaylist: function(playlist_id) {
      return (jQuery.inArray(playlist_id, this.playlist_ids) >= 0);
    },

    youTubeStatusText: {
      'YOUTUBE_UNKNOWN': 'Ready',
      'YOUTUBE_DISALLOWED': 'Disallowed',
      'YOUTUBE_TO_UPLOAD': 'Uploading',
      'YOUTUBE_UPLOADED': 'Uploaded',
      'YOUTUBE_UPLOAD_ERROR': 'Error'
    },

    prettyYouTubeStatus: function() {
      return this.youTubeStatusText[this.youTubeStatus];
    },

    renderChildren: function() {
      console.log('Warning: base class renderChildren called on widget ' + this.id);
    },

    renderYouTubeStatus: function() {
      jQuery(this.domNode).find('.youtube-status').replaceWith('<span class="youtube-status">' + this.prettyYouTubeStatus() + '</span>');
    },

    renderToDom: function() {
      this.renderYouTubeStatus();
      this.renderChildren();
    },

    updateMessage: function() {
      return { 'curation_status': this.curationStatus,
               'youtube_status': this.youTubeStatus,
               'playlist_ids': JSON.stringify(this.playlist_ids),
               '_token': this.csrfToken() };
    },

    saveStatus: function(error_callback) {
      var that = this;
      jQuery.ajax({
        type: 'PATCH',
        url: '/videos/' + this.vid_id,
        data: this.updateMessage(),
        dataType: 'json'
      })
        .done(function(response) {
          console.log('Video status update was OK: ');
          console.log(response);
          that.updateStatusFromResponse(response);
        })
        .fail(function(response) {
          console.log('Video status update returned an error: ');
          console.log(response);
          if (null != error_callback) {
            error_callback();
          }
        });
    },

    parsePlaylistIdList: function(list) {
      var array = [];
      for (var i = 0; i < list.length; i++) {
        array.push(Number(list[i]));
      }
      return array;
    },

    updateStatusFromResponse: function(response) {
      if (response.id == this.vid_id) {
        this.curationStatus = response.curation_status;
        this.youTubeStatus = response.youtube_status;
        this.playlist_ids = this.parsePlaylistIdList(response.playlist_ids);
        this.renderToDom();
      } else {
        console.log("Warning: Video ID in response did not match widget " + this.id)
      }
    },

    actionHandlers: {
      'approve': function() {
        this.curationStatus = 'CURATOR_ARCHIVED';
        this.saveStatus();
      },
      'approve_youtube': function() {
        this.curationStatus = 'CURATOR_ARCHIVED';
        this.youTubeStatus = 'YOUTUBE_TO_UPLOAD';
        this.saveStatus();
      },
      'youtube': function() {
        this.youTubeStatus = 'YOUTUBE_TO_UPLOAD';
        this.saveStatus();
      },
      'add_to_playlist': function(playlist) {
        if (! this.isInPlaylist(playlist)) {
          this.playlist_ids.push(playlist);
          if (this.curationStatus == 'CURATION_PENDING') {
            this.curationStatus = 'CURATOR_ARCHIVED';
          }
          this.saveStatus();
        }
      },
      'remove_from_playlist': function(playlist) {
        if (this.isInPlaylist(playlist)) {
          var i = this.playlist_ids.indexOf(playlist);
          this.playlist_ids.splice(i, 1);
          this.saveStatus();
        }
      },
      'trash': function() {
        if (this.playlist_ids.length < 1 ||
           confirm('Are you sure you want to send this video to the trash? It will be removed from all playlists.')) {
          this.curationStatus = 'CURATOR_DELETED';
          this.saveStatus();
        }
      },
      'untrash': function() {
        this.curationStatus = 'CURATOR_ARCHIVED';
        this.saveStatus();
      }
    },
    handleAction: function(action, playlist) {
      if (this.actionHandlers.hasOwnProperty(action)) {
        this.actionHandlers[action].call(this, playlist);
      }
    }
  },
  makeVideoWidget: function(widget_node, list) {
    var widget = Object.create(this.videoWidgetDefaults);
    widget.domNode = widget_node;
    var widget_jq = jQuery(widget_node);
    widget.id = widget_jq.attr('id');
    widget.list = list;
    widget.vid_id = widget_jq.data('videoid');
    widget.playlist_ids = widget.parsePlaylistIdList(widget_jq.data('playlist-ids'));
    widget.name = widget_jq.data('videoname');
    widget.curationStatus = widget_jq.data('curationstatus');
    widget.youTubeStatus = widget_jq.data('youtubestatus');
    return widget;
  },

  curationMenuItemDefaults: {
    htmlForAction: function(widget, action, pretty_action, playlist) {
      playlist_data = '';
      if (playlist != undefined) {
        playlist_data = ' data-playlist="' + playlist + '"';
      }
      return '<li><a class="curation-menu-item" data-widgetid="' +
        widget.id + '" data-action="' + action +
        '"' + playlist_data + ' href="#">' + pretty_action  + '</a></li>';
    }
  },
  makeApproveMenuItem: function() {
    var item = Object.create(this.curationMenuItemDefaults);
    item.render = function(widget) {
      // show only if curation status is pending
      if (widget.isPending()) {
        return this.htmlForAction(widget, 'approve', 'Approve');
      }
      return '';
    }
    return item;
  },

  makeYouTubeMenuItem: function() {
    var item = Object.create(this.curationMenuItemDefaults);
    item.render = function(widget) {
      // show if curation is ARCHIVED and youtube is unknown
      if (widget.isArchived() && (widget.isYouTubeReady())) {
        return this.htmlForAction(widget, 'youtube', 'Send To YouTube');
      }
      return '';
    }
    return item;
  },

  makeApproveYouTubeMenuItem: function() {
    var item = Object.create(this.curationMenuItemDefaults);
    item.render = function(widget) {
      // show if curation is pending and youtube is unknown
      if (widget.isPending() && (widget.isYouTubeReady())) {
        return this.htmlForAction(widget, 'approve_youtube', 'Approve + YouTube');
      }
      return '';
    }
    return item;
  },

  makePlaylistMenuItem: function(playlist_id, playlist_name) {
    var item = Object.create(this.curationMenuItemDefaults);
    item.render = function(widget) {
      if (widget.isTrashed()) {
        return '';
      }
      // show if video is not in this playlist, otherwise show removal link
      if (widget.isInPlaylist(playlist_id)) {
        return this.htmlForAction(widget, 'remove_from_playlist', 'Remove From ' + playlist_name, playlist_id);
      } else {
        return this.htmlForAction(widget, 'add_to_playlist', 'Add To ' + playlist_name, playlist_id);
      }
    }
    return item;
  },

  makeTrashMenuItem: function() {
    var item = Object.create(this.curationMenuItemDefaults);
    item.render = function(widget) {
      // show if not in curator_deleted state, otherwise show rescue link
      if (widget.isTrashed()) {
        return this.htmlForAction(widget, 'untrash', 'Recover From Trash');
      } else {
        return '<li class="divider"></li>' +
          this.htmlForAction(widget, 'trash', 'Move To Trash');
      }
    }
    return item;
  },

  curationControlsDefaults: {
    menuItems: [],
    playlistMenuItems: [],

    buttonStyles: {
      'CURATION_PENDING': {
        'btn-classes': 'btn-primary',
        'pretty-status': 'Pending'
      },
      'CURATOR_ARCHIVED': {
        'btn-classes': 'btn-success',
        'pretty-status': 'Approved'
      },
      'CURATOR_DELETED': {
        'btn-classes': 'btn-info',
        'pretty-status': 'Trashed'
      }
    },

    curationButtonStyle: function(widget) {
      return this.buttonStyles[widget.curationStatus]['btn-classes'];
    },

    prettyCurationStatus: function(widget) {
      return this.buttonStyles[widget.curationStatus]['pretty-status'];
    },

    playlistButtonHtml: function(widget) {
      html = '<li class="video-control-menu"><div class="btn-group playlist-controls">';
      if (widget.curationStatus == 'CURATOR_DELETED') {
        html += '<button type="button" class="btn btn-default" disabled="disabled">' +
          'Playlists</button>';
      } else {
        html += '<button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">' +
          'Playlists <span class="caret"></span></button><ul class="dropdown-menu playlist-dropdown-menu" role="menu">';
        for (var i = 0; i < this.playlistMenuItems.length; i++) {
          html += this.playlistMenuItems[i].render(widget);
        }
        html += '</ul>';
      }
      html += '</div></li>';
      return html;
    },

    render: function(widget) {
      var html = '<ul class="video-controls">' +
        '<li class="video-control-menu"><div class="btn-group curation-controls">' +
        '<button type="button" class="btn ' + this.curationButtonStyle(widget) +
        ' dropdown-toggle" data-toggle="dropdown">' + this.prettyCurationStatus(widget) +
        ' <span class="caret"></span></button><ul class="dropdown-menu" role="menu">';
      for (var i = 0; i < this.menuItems.length; i++) {
        html += this.menuItems[i].render(widget);
      }
      html += '</ul></div></li>' + this.playlistButtonHtml(widget) + '</ul>';
      return html;
    }
  },

  makeCurationControls: function(all_playlists) {
    var controls = Object.create(this.curationControlsDefaults);
    // Add the curation menu items to the menu.
    controls.menuItems = [];
    controls.menuItems.push(this.makeApproveMenuItem());
    controls.menuItems.push(this.makeApproveYouTubeMenuItem());
    controls.menuItems.push(this.makeYouTubeMenuItem());
    controls.menuItems.push(this.makeTrashMenuItem());
    controls.playlistMenuItems = [];
    for (var i = 0; i < all_playlists.length; i++) {
      controls.playlistMenuItems.push(this.makePlaylistMenuItem(all_playlists[i].id, all_playlists[i].name));
    }
    return controls;
  },

  makeCurationWidget: function(widget_node, list) {
    var widget = this.makeVideoWidget(widget_node, list);
    widget.renderChildren = function() {
      jQuery(this.domNode).find('ul.video-controls').replaceWith(this.list.controls.render(this));
    }
    widget.renderToDom();
    return widget;
  },

  makeCurationList: function(list_node) {
    var list = this.makeVideoList('curation-list', list_node);
    list.controls = this.makeCurationControls(list.allPlaylists);
    // build a curationWidget for every video in the list
    var list_jq = jQuery(list_node);
    var that = this;
    list_jq.find('.video-widget').each(function() {
      list.addWidget(that.makeCurationWidget(this, list));
    });
    // listen for menu clicks from inside this list
    list_jq.on('click', 'a.curation-menu-item', [], function(event) {
      var jq = jQuery(this);
      var widget = list.widgetsById[jq.data('widgetid')];
      if (widget != undefined) {
        widget.handleAction(jq.data('action'), jq.data('playlist'));
      }
      // close the curation menu
      jQuery(widget.domNode).find('.video-controls li.btn-group').removeClass('open');
      return false;
    });

    return list;
  },

  shufflerControlsDefaults: {
    renderButton: function(action, li_class, icon_image, alt_text, widget) {
      return '<li class="' + li_class +
        '"><button type="button" data-action="' + action +
        '" data-widgetid="' + widget.id +
        '" class="btn btn-default shuffler-action-button">' +
        '<img src="' + icon_image + '" alt=" ' + alt_text +
        '" </button></li>';
    },
    renderPlaylistControls: function(widget) {
      return '<div class="shuffler-controls">' + "\n<ul>" +
        this.renderButton('shuffle_up', 'shuffle-button-up', '/img/arrow-thick-top.svg', 'move up', widget) +
        this.renderButton('shuffle_down', 'shuffle-button-down', '/img/arrow-thick-bottom.svg', 'move down', widget) +
        this.renderButton('move_widget', 'shuffle-button-move', '/img/arrow-thick-right.svg', 'remove', widget) +
        "\n</ul>\n</div>";
    },
    renderLibraryControls: function(widget) {
      return '<div class="shuffler-controls">' + "\n<ul>" +
        this.renderButton('move_widget', 'shuffle-button-move', '/img/arrow-thick-left.svg', 'add to playlist', widget) +
        "\n</ul>\n</div>";
    }
  },
  makePlaylistShufflerControls: function(widget) {
    var controls = Object.create(this.shufflerControlsDefaults);
    controls.render = controls.renderPlaylistControls;
    return controls;
  },
  makeLibraryShufflerControls: function(widget) {
    var controls = Object.create(this.shufflerControlsDefaults);
    controls.render = controls.renderLibraryControls;
    return controls;
  },

  makeShufflerWidget: function(widget_node, list) {
    var widget = this.makeVideoWidget(widget_node, list);
    widget.actionHandlers['shuffle_up'] = function() {
      this.list.shuffleWidgetUp(this);
    };
    widget.actionHandlers['shuffle_down'] = function() {
      this.list.shuffleWidgetDown(this);
    };
    widget.actionHandlers['move_widget'] = function() {
      this.list.moveWidget(this, true);
    };
    widget.renderChildren = function() {
      jQuery(this.domNode).find('div.shuffler-controls')
        .replaceWith(this.list.controls.render(this));
    };
    widget.renderToDom();
    return widget;
  },

  makeShuffler: function(name, panel, shuffler_node, controls) {
    var shuffler = this.makeVideoList(name, shuffler_node, controls);
    // augment the shuffler
    shuffler.panel = panel;
    shuffler.moveWidget = function(widget, append) {
      this.panel.moveWidget(this, widget, append);
    };
    // build a shufflerWidget for every video in the shuffler list
    var shuffler_jq = jQuery(shuffler_node);
    var that = this;
    shuffler_jq.find('.video-widget').each(function() {
      shuffler.addWidget(that.makeShufflerWidget(this, shuffler));
    });

    // listen for button clicks from inside this shuffler
    shuffler_jq.on('click', 'button.shuffler-action-button', [], function(event) {
      var jq = jQuery(this);
      var widget = shuffler.widgetsById[jq.data('widgetid')];
      if (widget != undefined) {
        widget.handleAction(jq.data('action'));
      }
      return false;
    });

    // listen for drag events
    shuffler_jq.on("sortstop", function(event, ui) {
      // a drag stopped in this shuffler;
      // call alertChanged to ensure sort order is up to date
      shuffler.alertChanged();
    });
    shuffler_jq.on("sortremove", function(event, ui) {
      // handle the move of a widget from one shuffler to another
      var widget = shuffler.widgetsById[ui.item[0].id];
      shuffler.moveWidget(widget, false);
    });

    return shuffler;
  },

  makePlaylistShuffler: function(panel, shuffler_node) {
    var jq = jQuery(shuffler_node);
    var playlistId = jq.data('playlist-id');
    var name = 'playlist ' + playlistId
    var shuffler = this.makeShuffler(name, panel, shuffler_node,
                                     this.makePlaylistShufflerControls());
    shuffler.playlistId = playlistId
    // Make an API call to update the playlist in the database to match
    // the videos, and their order, in this shuffler.
    shuffler.alertChanged = function() {
      // use $(playlist_node).sortable("serialize");
      console.log("Recording changed contents of list " + this.name);
      var ordered_ids = jQuery(this.domNode).sortable("toArray", {'attribute': 'data-videoid'})
        .filter(function(e) { return e != '' });
      console.log(ordered_ids);
      this.saveStatus(ordered_ids);
    };
    shuffler.saveStatus = function(widget_ids, error_callback) {
      var that = this;
      jQuery.ajax({
        type: 'PATCH',
        url: '/playlists/' + this.playlistId,
        dataType: 'json',
        data: {
          'video_ids': JSON.stringify(widget_ids),
          '_token': this.csrfToken
        }
      })
        .done(function(response) {
          console.log('Playlist status update was OK: ');
          console.log(response);
        })
        .fail(function(response) {
          console.log('Playlist status update returned an error: ');
          console.log(response);
          if (null != error_callback) {
            error_callback();
          }
        });
    };
    return shuffler;
  },

  makeLibraryShuffler: function(panel, shuffler_node) {
    return this.makeShuffler('library',
                             panel, shuffler_node, this.makeLibraryShufflerControls());
  },

  playlistPanelDefaults: {
    playlistShuffler: null,
    libraryShuffler: null,
    // Remove the given widget from the given shuffler and place it on the other.
    moveWidget: function(source, widget, append) {
      var target;
      if (source == this.playlistShuffler) {
        target = this.libraryShuffler;
      } else if (source == this.libraryShuffler) {
        target = this.playlistShuffler;
      } else {
        return;
      }
      source.removeWidget(widget);
      target.addWidget(widget, append);
      source.alertChanged();
      target.alertChanged();
    }
  },
  makePlaylistEditPanel: function(panel_node) {
    var panel = Object.create(this.playlistPanelDefaults);
    var playlist_node = jQuery(panel_node).find('#playlist-editor');
    panel.playlistShuffler = this.makePlaylistShuffler(panel, playlist_node);

    var library_node = jQuery(panel_node).find('#playlist-editor-library');
    panel.libraryShuffler = this.makeLibraryShuffler(panel, library_node);
    // Make the lists sortable and connect them to each other.
    jQuery(playlist_node).sortable({
      items: "> li",
      connectWith: '#playlist-editor-library'
    });
    jQuery(library_node).sortable({
      items: "> li",
      connectWith: '#playlist-editor'
    });

    return panel;
  }
};

jQuery(document).ready(function($) {
  $('.curation-list').each(function() {
    $(this).data('curationList', BlVideo.makeCurationList(this));
  });

  $('#playlist-edit-panel').each(function() {
    $(this).data('playlistPanel', BlVideo.makePlaylistEditPanel(this));
  });
});
